
import React, { useState, useEffect } from 'react';
import Api from '../components/Api';
import Unav from '../components/Unav';
import Footer from '../components/Footer';
import Code from '../components/Code';
import { Link } from 'react-router-dom';

function Complaint() {

  const [searchTerm, setSearchTerm] = useState('');
  const [showAddComplaint, setShowAddComplaint] = useState(true);
  const [showViewComplaint, setShowViewComplaint] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const [message, setMessage] = useState('');
  const [complaint_title, setComplaint_title] = useState('');
  const [complaint_nature, setComplaint_nature] = useState('');
  const [complaint_type, setComplaint_type] = useState('');
  const [complaint_category, setComplaint_category] = useState('');
  const [attachment, setAttachment] = useState(''); 
  const [complaint_member, setComplaint_member] = useState('');
  const [complaint_date, setComplaint_date] = useState('');
  const [remark, setRemark] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [dataList, setDataList] = useState([]);
  const [selectedComplaint, setSelectedComplaint] = useState(null); 
  const handleViewComplaint = (complaint) => {
    setSelectedComplaint(complaint); 
  };
  const toggleSection = (section) => {
    if (section === 'add') {
      setShowAddComplaint(true);
      setShowViewComplaint(false);
    } else if (section === 'view') {
      setShowAddComplaint(false);
      setShowViewComplaint(true);
    }
  };

  

  useEffect(() => {
    const fetchData = async () => {
      const phone = JSON.parse(localStorage.getItem('userDetails'))?.phone || '';

      if (phone) {
        try {
          const response = await Api.post(`/complaintlist`, { phone });
          const result = response.data;

          if (result.message === "Data List") {
            setDataList(result.data); 
          } else {
            setMessage("Failed to fetch data.");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setMessage("An error occurred while fetching data.");
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      const phone = JSON.parse(localStorage.getItem('userDetails'))?.phone || '';
      if (phone) {
        try {
          const response = await Api.post(`/userinfo`, { phone });
          const result = response.data;
          if (result.message === 'User Detail Fetch Successfully') {
            setUserDetail(result.userDetail);
          } else {
            setMessage('Failed to fetch user data.');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setMessage('An error occurred while fetching user data.');
        }
      }
    };
    fetchUserData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');
    try {
      const response = await Api.post('/add-complaint', {
        name: userDetail.name,
        phone: userDetail.phone,
        email: userDetail.email,
        role: userDetail.role,
        flat: userDetail.flat,
        tower: userDetail.tower,
        complaint_title,
        complaint_nature,
        complaint_type,
        complaint_category,
        complaint_date,
        attachment,
        complaint_member,
        remark,
      });
      const result = response.data;
      if (result.message === 'Form submitted successfully') {
        setSuccess(result.message);
        setRemark('');
      } else {
        setError(result.message || 'Submission failed');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Submission failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">
          <div className="row">
            <div className="col-lg-4 col-6 bg-primary" id="alink">
              <a type='button' className="text-white" onClick={() => toggleSection('add')}>Add Complaint</a>
            </div>
            <div className="col-lg-4 col-6 bg-danger" id="alink">
              <a type='button' className="text-white" onClick={() => toggleSection('view')}>View Complaint</a>
            </div>
          </div>
          <div className="row mt-2 me-2 ms-1">
            <div className="col-12 bg-white p-3 mb-4" style={{overflowX:'scroll' }}>
              {showAddComplaint && (
                <div className="row">
                  
                  <div className="col-12">
                    <h5 className="radiant">Add Complaint</h5>
                    <hr />
                  </div>
                  <form onSubmit={handleSubmit}>

                    {/* Form Inputs */}
                    <div className="row">
                      {/* Role */}
                      <div className="col-lg-6 col-12 mt-3 form-group">
                        <div className="row">
                          <div className="col-lg-4 col-12 mt-2">
                            <label className="mt-2"><b>I am an/a</b></label>
                          </div>
                          <div className="col-lg-8 col-12 mt-2">
                            <input
                              type="text"
                              className="w-100 form-control text-uppercase"
                              value={userDetail.role || ''}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      {/* Name */}
                      <div className="col-lg-6 col-12 mt-3 form-group">
                        <div className="row">
                          <div className="col-lg-4 col-12 mt-2">
                            <label htmlFor="account-fn"><b>Name</b> <span className="text-danger">*</span></label>
                          </div>
                          <div className="col-lg-8 col-12 mt-2">
                            <input
                              className="w-100 form-control text-uppercase"
                              type="text"
                              name="name"
                              value={userDetail.name || ''}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      {/* Phone */}
                      <div className="col-lg-6 col-12 mt-3 form-group">
                        <div className="row">
                          <div className="col-lg-4 col-12 mt-2">
                            <label htmlFor="account-fn"><b>Phone</b> <span className="text-danger">*</span></label>
                          </div>
                          <div className="col-lg-8 col-12 mt-2">
                            <div className="row">
                              <div className="col-4">
                                <Code />
                              </div>
                              <div className="col-8">
                                <input
                                  className="w-100 form-control text-uppercase"
                                  type="text"
                                  name="phone"
                                  value={userDetail.phone || ''}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Email */}
                      <div className="col-lg-6 col-12 mt-3 form-group">
                        <div className="row">
                          <div className="col-lg-4 col-12 mt-2">
                            <label htmlFor="account-fn"><b>E-Mail ID:</b> <span className="text-danger">*</span></label>
                          </div>
                          <div className="col-lg-8 col-12 mt-2">
                            <input
                              className="form-control text-uppercase"
                              type="text"
                              id="account-fn"
                              value={userDetail.email || ''}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      {/* Tower */}
                      <div className="col-lg-6 col-12 mt-3 form-group">
                        <div className="row">
                          <div className="col-lg-4 col-12 mt-2">
                            <label htmlFor="account-fn"><b>Tower</b> <span className="text-danger">*</span></label>
                          </div>
                          <div className="col-lg-8 col-12 mt-2">
                            <input
                              className="form-control text-uppercase"
                              type="text"
                              id="account-fn"
                              value={userDetail.tower || ''}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      {/* Flat Number */}
                      <div className="col-lg-6 col-12 mt-3 form-group">
                        <div className="row">
                          <div className="col-lg-4 col-12 mt-3">
                            <label htmlFor="account-fn"><b>Flat Number</b> <span className="text-danger">*</span></label>
                          </div>
                          <div className="col-lg-8 col-12 mt-2">
                            <input
                              className="form-control text-uppercase"
                              type="text"
                              id="account-fn"
                              value={userDetail.flat || ''}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 mt-3 form-group">
                        <div className="row">
                          <div className="col-lg-4 col-12 mt-3">
                            <label htmlFor="account-fn"><b>Complain Title</b> <span className="text-danger">*</span></label>
                          </div>
                          <div className="col-lg-8 col-12 mt-2">
                            <input
                              className="form-control "
                              type="text"
                              placeholder='Title'
                              value={complaint_title} onChange={(e) => setComplaint_title(e.target.value)} required />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 mt-3 form-group">
                        <div className="row">
                          <div className="col-lg-4 col-12 mt-3">
                            <label htmlFor="account-fn"><b>Nature</b> <span className="text-danger">*</span></label>
                          </div>
                          <div className="col-lg-8 col-12 mt-2">
                            <select name="nature"  className='form-select'
                             value={complaint_nature} onChange={(e) => setComplaint_nature(e.target.value)} required>
                              <option value="">Select Nature</option>
                              <option value="complaint">Complaint</option>
                              <option value="suggestion">Suggestion</option>
                              <option value="request">Request</option>
                              <option value="maintenance">Maintenance Request</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 mt-3 form-group">
                        <div className="row">
                          <div className="col-lg-4 col-12 mt-3">
                            <label htmlFor="account-fn"><b>Type</b> <span className="text-danger">*</span></label>
                          </div>
                          <div className="col-lg-8 col-12 mt-2">
                            <select name="nature"  className="form-select" 
                             value={complaint_type} onChange={(e) => setComplaint_type(e.target.value)} required >
                              <option value="">Select Type</option>
                              <option value="Individual">Individual</option>
                              <option value="Compound">Compound</option>

                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 mt-3 form-group">
                        <div className="row">
                          <div className="col-lg-4 col-12 mt-3">
                            <label htmlFor="account-fn"><b> Category </b> <span className="text-danger">*</span></label>
                          </div>
                          <div className="col-lg-8 col-12 mt-2">
                            <select name="complaint_category"  className="form-select"
                             value={complaint_category} onChange={(e) => setComplaint_category(e.target.value)} >
                              <option value="">Select Category</option>
                              <option value="Accounts & Billing">Accounts & Billing</option>
                              <option value="Carpenter">Carpenter</option>
                              <option value="Civil">Civil</option>
                              <option value="Club & Facility">Club & Facility</option>
                              <option value="Electricity">Electricity</option>
                              <option value="Fire & Safety">Fire & Safety</option>
                              <option value="Housekeeping">Housekeeping</option>
                              <option value="Intercom">Intercom</option>
                              <option value="Lift">Lift</option>
                              <option value="Mason">Mason</option>
                              <option value="Painter">Painter</option>
                              <option value="Parking">Parking</option>
                              <option value="Plumbing">Plumbing</option>
                              <option value="Security">Security</option>
                              <option value="Others">Others</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 mt-3 form-group">
                        <div className="row">
                          <div className="col-lg-4 col-12 mt-3">
                            <label htmlFor="account-fn"><b> Member </b> </label>
                          </div>
                          <div className="col-lg-8 col-12 mt-2">
                            <select name="member" className="form-select"
                            value={complaint_member} onChange={(e) => setComplaint_member(e.target.value)} >
                              <option value="">Select Member</option>
                              <option value="Member 1">Member 1</option>
                              <option value="Member 2">Member 2</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 mt-3 form-group">
                        <div className="row">
                          <div className="col-lg-4 col-12 mt-3">
                            <label htmlFor="account-fn"><b> Date </b> </label>
                          </div>
                          <div className="col-lg-8 col-12 mt-2">
                            <input type="date" className='form-control'   value={complaint_date}
                             onChange={(e) => setComplaint_date(e.target.value)} required />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 mt-3 form-group">
                        <div className="row">
                          <div className="col-lg-5 col-12 mt-3">
                            <label htmlFor="account-fn"><b> Proof of Complaint </b> </label>
                          </div>
                          <div className="col-lg-7 col-12 mt-2">
                            <input type="file" className='form-control'
                             value={attachment} 
                             onChange={(e) => setAttachment(e.target.value)} />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 mt-3 form-group">
                        <div className="row">
                          <div className="col-lg-12 col-12 ">
                            <label htmlFor="account-fn"><b> Remarks </b> </label>
                          </div>
                          <div className="col-lg-12 col-12">
                            <textarea name="text" id="" className='form-control'
                             value={remark}
                             onChange={(e) => setRemark(e.target.value)}> </textarea>
                          </div>
                        </div>
                      </div>


                      {/* Submit Button */}

                    </div>
                    <div className="row justify-content-end">
                      <div className="col-lg-3 col-12 mt-3 mb-3">
                        <button className="bg-primary text-white form-control text-uppercase w-100" type="submit">
                          {loading ? 'Loading...' : 'Submit'}
                        </button>
                      </div>
                    </div>

                    {/* Display success or error message */}
                    {error && <div className="alert alert-danger text-center p-2 mt-2  ">{error}</div>}
                    {success && <div className="alert alert-success text-center p-2  mt-2 ">{success}</div>}
                  </form>

                </div>
              )}


       {showViewComplaint && (
        <div className="row" style={{height:'50%'}}>
          {message && <p>{message}</p>} {/* Display error or success message */}

          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Flat</th>
                <th>Complaint</th>
                <th>Status</th>
                <th>Updated_At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {dataList.length > 0 ? (
                dataList
                  .filter((complaint) =>
                    complaint.name.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                  .map((complaint, index) => (
                    <tr key={index}>
                      <td>{complaint.id}</td>
                      <td>{complaint.name}</td>
                      <td>{complaint.flat}</td>
                      <td>{complaint.complaint_category}</td>
                      <td>
                        <span className={`badge ${complaint.current_status === 'Resolved' ? 'bg-success' : 'bg-warning'}`}>
                          {complaint.current_status}
                        </span>
                      </td>
                      <td>{new Date(complaint.updated_at).toLocaleString()}</td>
                      <td>
                        <button
                          className="btn btn-warning btn-sm"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => handleViewComplaint(complaint)}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="7">No complaints found</td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Modal for viewing complaint details */}
          <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header bg-info">
                  <h6 className="text-uppercase radiant" id="exampleModalLabel">Complaint Information</h6>
                  <button type="button" className="btn btn-danger" data-bs-dismiss="modal" aria-label="Close">
                    Close
                  </button>
                </div>
                <div className="modal-body">
                  {selectedComplaint ? (
                    
                    <div className='row justify-content-center' >
                      <div className="col-lg-11 col-12 ">
                      <h6 className='mb-4 radiant'>View Complaint</h6>
                       
                      <div className="row mt-2">
                        <div className="col-4">
                        <strong>Name : </strong>
                        </div>
                        <div className="col-8"> <p> {selectedComplaint.name}</p></div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-4">
                        <strong>Flat Number : </strong>
                        </div>
                        <div className="col-8"> <p>{selectedComplaint.flat}</p></div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-4">
                        <strong>Complaint Details: </strong>
                        </div>
                        <div className="col-8"> <p>{selectedComplaint.complaint_title} </p></div>
                      </div>
                    
                      <div className="row mt-2">
                        <div className="col-4">
                        <strong>Status : </strong>
                        </div>
                        <div className="col-8"> <p> {selectedComplaint.current_status} </p></div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-4">
                        <strong>Master Comment : </strong>
                        </div>
                        <div className="col-8">
                      
                        <p><strong> Comment Date & Time : </strong>{selectedComplaint.complaint} </p>
                        <p><strong> Comment  </strong>{selectedComplaint.complaint} </p>
                      
                        </div>
                      </div>
                      </div>
                    
                    
                    </div>
                  ) : (
                    <p>No complaint selected.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Complaint;
